import APIService from './APIService';

export default {
  getAll() {
    return APIService.apiCall().get('/mutacao-bdgd/get-all');
  },
  deletar(id) {
    return APIService.apiCall().get(`/mutacao-bdgd/excluir/${id}`);
  },
  salvarRelatorio(postData) {
    return APIService.apiCall().post(
      '/mutacao-bdgd/save',
      JSON.stringify(postData)
    );
  },
  executarRelatorio(id) {
    return APIService.apiCall().patch(`/mutacao-bdgd/executar/${id}`);
  },
  baixarArquivoModelo(tipo, config = {}) {
    return APIService.apiCall().get(
      `/mutacao-bdgd/download-arquivo-modelo/${tipo}`,
      { responseType: 'blob', ...config }
    );
  },
  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/mutacao-bdgd/download-arquivo-zip-resultado/${id}`,
      { responseType: 'blob', ...config }
    );
  },
};
<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-compare-horizontal"
            inline
            class="px-5 py-3"
            style="min-height: 840px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">Novo Relatório</div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-text-field
                  label="Título"
                  v-model="titulo"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-card tile>
                  <v-subheader> BDGD Antiga </v-subheader>
                  <v-divider />
                  <v-card-text>
                    <select-all-bdgd-versions
                      label="Versão da BDGD"
                      :companyId="companyId"
                      @bdgdVersionSelected="
                        (event) => handleBdgdVersionSelected('A', event)
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card tile>
                  <v-subheader> BDGD Atual </v-subheader>
                  <v-divider />
                  <v-card-text>
                    <select-all-bdgd-versions
                      label="Versão da BDGD"
                      :companyId="companyId"
                      @bdgdVersionSelected="
                        (event) => handleBdgdVersionSelected('B', event)
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card tile>
                  <v-subheader> Base AIS </v-subheader>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="5">
                        <input-date
                          label="Data do AIS"
                          :rules="{ min: 10 }"
                          @dataSelected="(data) => (parametros.data_ais = data)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <input-date
                          label="Início do AIS"
                          :rules="{ min: 10 }"
                          @dataSelected="
                            (data) => (parametros.data_inicio = data)
                          "
                        />
                      </v-col>
                      <v-col
                        class="text-center pt-6"
                        style="min-width: 60px !important"
                        cols="1"
                      >
                        <span
                          style="color: rgba(0, 0, 0, 0.54); font-size: 15px"
                        >
                          até
                        </span>
                      </v-col>
                      <v-col cols="5">
                        <input-date
                          label="Final do AIS"
                          :rules="{ min: 10 }"
                          @dataSelected="(data) => (parametros.data_fim = data)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-file-upload-outline"
            inline
            class="px-5 py-3"
            style="min-height: 840px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">Arquivos CSV</div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <upload-files
                  :allowedFiles="allowedFiles"
                  @file:uploaded="handleFileUploaded"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div v-show="numeroArquivosEnviados">
                  <div class="text-center display-1 font-weight-normal mb-10">
                    {{ numeroArquivosEnviados }} arquivos enviados,
                    {{ numeroArquivosMapeados }} mapeados e
                    {{ numeroArquivosNaoMapeados }} não mapeados
                  </div>
                  <v-alert
                    v-show="numeroArquivosNaoMapeados"
                    color="red"
                    outlined
                    type="warning"
                    class="mb-10"
                  >
                    {{ mensagemArquivosNaoMapeados }}
                  </v-alert>
                </div>
                <v-data-table
                  :headers="[
                    {
                      sortable: false,
                      text: 'Tipo do CSV',
                      value: 'tipo_csv'
                    },
                    {
                      sortable: false,
                      text: 'Arquivo Enviado',
                      value: 'arquivo_enviado'
                    },
                    {
                      sortable: false,
                      text: 'Ações',
                      value: 'actions',
                      align: 'center'
                    }
                  ]"
                  :items="arquivos"
                  :headerProps="{
                    sortByText: 'Ordenar Por'
                  }"
                  disable-pagination
                  hide-default-footer
                  dense
                  no-data-text="Nenhum arquivo enviado"
                  :mobile-breakpoint="800"
                >
                  <template v-slot:[`item.tipo_csv`]="{ item }">
                    <span
                      :style="{ color: item.arquivo_enviado ? 'green' : 'red' }"
                    >
                      {{ item.tipo_csv }}
                    </span>
                  </template>
                  <template v-slot:[`item.arquivo_enviado`]="{ item }">
                    <span
                      v-if="item.arquivo_enviado"
                      :style="{ color: 'green' }"
                    >
                      {{ item.arquivo_enviado.arquivo }}
                    </span>
                    <span
                      v-else
                      :style="{ color: 'red' }"
                    >
                      --
                    </span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="px-1"
                          min-width="0"
                          fab
                          icon
                          x-small
                          v-on="on"
                          v-bind="attrs"
                          @click="baixarArquivoModelo(item.tipo_csv)"
                          :loading="
                            downloadProgressData[item.tipo_csv] !== undefined
                              ? true
                              : false
                          "
                        >
                          <v-icon small> mdi-download </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Baixar o arquivo modelo para
                        {{ item.tipo_csv }}
                      </span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="item.arquivo_enviado && item.arquivo_enviado.id"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="gray"
                          min-width="0"
                          class="px-1"
                          fab
                          icon
                          x-small
                          v-on="on"
                          v-bind="attrs"
                          @click="
                            () =>
                              removeMapeamentoArquivo(item.arquivo_enviado.id)
                          "
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Clique aqui para excluir o arquivo
                        {{ item.arquivo }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <general-progress-bars
                  class="mt-5 mb-5"
                  :items="downloadProgressData"
                  prefixText="Baixando arquivo modelo ..."
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="salvarRelatorio"
            :disabled="!podeSalvarRelatorio"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import MutacaoBdgdService from '@/services/MutacaoBdgdService';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions';
import InputDate from '@/components/general/InputDate';
import UploadFiles from '@/components/general/UploadFiles/Index';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';
import routes from '@/store/modules/routes';

export default {
  components: {
    SelectAllBdgdVersions,
    InputDate,
    UploadFiles,
    GeneralProgressBars
  },
  data: () => ({
    loading: false,
    downloadProgressData: {},
    titulo: null,
    bdgdA: {},
    bdgdB: {},
    parametros: {
      data_ais: null,
      data_inicio: null,
      data_fim: null
    },
    allowedFiles: [],
    uploadedFiles: [],
    arquivos: [
      {
        tipo_csv: 'AIS',
        arquivo_enviado: null
      },
      {
        tipo_csv: 'BAIXAS',
        arquivo_enviado: null
      },
      {
        tipo_csv: 'INCR',
        arquivo_enviado: null
      },
      {
        tipo_csv: 'PCBB',
        arquivo_enviado: null
      },
      {
        tipo_csv: 'PPBB',
        arquivo_enviado: null
      }
    ]
  }),
  methods: {
    handleBdgdVersionSelected(base, bdgdVersion) {
      if (base === 'A') {
        this.bdgdA = bdgdVersion;
      } else {
        this.bdgdB = bdgdVersion;
      }
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => {
        this.uploadedFiles.push(file);
        this.efetuaMapeamentoArquivo(file);
      });
    },
    efetuaMapeamentoArquivo(arquivoEnviado) {
      const { arquivo } = arquivoEnviado;
      const index = this.arquivos.findIndex((linha) =>
        arquivo.toUpperCase().startsWith(linha.tipo_csv.toUpperCase())
      );
      if (index < 0) return;
      this.arquivos[index].arquivo_enviado = arquivoEnviado;
    },
    removeMapeamentoArquivo(arquivoId) {
      const index = this.arquivos.findIndex(
        (linha) =>
          linha.arquivo_enviado && linha.arquivo_enviado.id === arquivoId
      );
      if (index < 0) return;
      this.arquivos[index].arquivo_enviado = null;
    },
    getArquivoId(tipoCsv) {
      const index = this.arquivos.findIndex(
        (linha) => linha.tipo_csv === tipoCsv
      );
      if (index < 0) return null;
      return this.arquivos[index].arquivo_enviado
        ? this.arquivos[index].arquivo_enviado.id
        : null;
    },
    async salvarRelatorio() {
      this.loading = true;
      try {
        const relatorio = {
          nome: this.titulo,
          parametros: this.parametros,
          bdgd_anterior_versao_id: this.bdgdAVersaoId,
          bdgd_atual_versao_id: this.bdgdBVersaoId,
          arquivo_ais_id: this.arquivoAisId,
          arquivo_pcbb_id: this.arquivoPcbbId,
          arquivo_incr_id: this.arquivoIncrId,
          arquivo_ppbb_id: this.arquivoPpbbId,
          arquivo_baixas_id: this.arquivoBaixasId
        };
        await MutacaoBdgdService.salvarRelatorio(relatorio);
        this.$toast.success('Relatório cadastrado com sucesso.', '', {
          position: 'topRight'
        });
        this.$router.push({
          name: routes.state.appRoutes['MUTACAO_BDGD_HISTORICO']
        });
      } catch (e) {
        this.$toast.error('Ocorreu um erro ao cadastrar o relatório.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    baixarArquivoModelo(id) {
      const nomeArquivo = `arquivo-modelo-${id.toLowerCase()}.zip`;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      MutacaoBdgdService.baixarArquivoModelo(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error('Erro ao baixar o arquivo modelo.', '', {
            position: 'topRight'
          });
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  },
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },
    bdgdAVersaoId() {
      return this.bdgdA.id;
    },
    bdgdBVersaoId() {
      return this.bdgdB.id;
    },
    bdgdAValida() {
      return !!this.bdgdAVersaoId;
    },
    bdgdBValida() {
      return !!this.bdgdBVersaoId;
    },
    arquivosMapeados() {
      return this.arquivos
        .filter((arquivo) => arquivo.arquivo_enviado !== null)
        .map((arquivo) => arquivo.tipo_csv);
    },
    arquivosNaoMapeados() {
      return this.arquivos
        .filter((arquivo) => arquivo.arquivo_enviado === null)
        .map((arquivo) => arquivo.tipo_csv);
    },
    numeroArquivosEnviados() {
      return this.uploadedFiles.length;
    },
    numeroArquivosMapeados() {
      return this.arquivosMapeados.length;
    },
    numeroArquivosNaoMapeados() {
      return this.arquivosNaoMapeados.length;
    },
    mensagemArquivosNaoMapeados() {
      const arquivosNaoMapeados = this.arquivosNaoMapeados.join(', ');
      return `Os seguintes arquivos CSV ainda não foram enviados: ${arquivosNaoMapeados}`;
    },
    arquivoAisId() {
      return this.getArquivoId('AIS');
    },
    arquivoPcbbId() {
      return this.getArquivoId('PCBB');
    },
    arquivoIncrId() {
      return this.getArquivoId('INCR');
    },
    arquivoPpbbId() {
      return this.getArquivoId('PPBB');
    },
    arquivoBaixasId() {
      return this.getArquivoId('BAIXAS');
    },
    podeSalvarRelatorio() {
      const {
        data_ais: dataAis,
        data_inicio: dataInicio,
        data_fim: dataFim
      } = this.parametros;
      return (
        this.titulo !== '' &&
        this.bdgdAValida &&
        this.bdgdBValida &&
        this.bdgdAVersaoId !== this.bdgdBVersaoId &&
        dataAis !== null &&
        dataInicio !== null &&
        dataFim !== null &&
        new Date(dataInicio) < new Date(dataFim) &&
        this.numeroArquivosNaoMapeados === 0 &&
        this.arquivoAisId > 0 &&
        this.arquivoBaixasId > 0 &&
        this.arquivoIncrId > 0 &&
        this.arquivoPcbbId > 0 &&
        this.arquivoPpbbId > 0
      );
    }
  }
};
</script>
